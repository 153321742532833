<template>
  <div class="minWidth1000">
    <custom-actions show-back>
      <el-form inline label-width="100px">
        <el-form-item label="日期">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click='exportFile'>导出</el-button>
        </el-form-item>
      </el-form>
    </custom-actions>

    <el-card shadow="never" class="mt15" :body-style="{ padding: '15px' }">
      <el-table stripe size="mini" border v-loading="loading" :data="tableData">
        <el-table-column min-width="120" label="设备串号" prop="deviceNum"></el-table-column>
        <el-table-column min-width="120" label="日期" prop="dataDate"></el-table-column>
        <el-table-column min-width="120" label="当日耗电量" prop="electricityNum"></el-table-column>
        <el-table-column min-width="120" label="电表读数" prop="electricityValue"></el-table-column>
        <!-- <el-table-column min-width="120" label="基站内空开串号" prop="insideDeviceNum"></el-table-column>
        <el-table-column min-width="120" label="基站内设备当日耗电量" prop="insideElectricityNum"></el-table-column>
        <el-table-column min-width="120" label="基站内设备电表读数" prop="insideElectricityValue"></el-table-column> -->
      </el-table>

      <el-pagination
        class="mt10"
        :current-page.sync="page"
        background
        layout="total, prev, pager, next"
        :total="total"
        @current-change="loadData">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { filterEmpyKey } from '@/utils/utils.js'

export default {
  name: 'History',
  data () {
    return {
      tableData: [],
      page: 1,
      total: 0,
      loading: false,
      dateRange: [],
      searchForm: {
        deviceNum: null,
        startTime: null,
        endTime: null
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.searchForm.deviceNum = this.$route.query.deviceNum
    this.loadData()
  },
  methods: {
    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.smartDevice.selectDeviceDataByPage, {
        params: {
          page: this.page,
          ...this.searchForm
        }
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      }).finally(() => {
        this.loading = false
      })
    },
    onSearch () {
      this.page = 1
      if (this.dateRange && this.dateRange.length) {
        this.searchForm.startTime = this.dateRange[0]
        this.searchForm.endTime = this.dateRange[1]
      } else {
        this.searchForm.startTime = null
        this.searchForm.endTime = null
      }
      this.loadData()
    },
    exportFile () {
      const form = filterEmpyKey(this.searchForm)

      const token = this.$store.state?.token
      let str = ''
      for (var key in form) {
        str += key + '=' + (form[key] || '') + '&'
      }
      str = str.substr(0, str.length - 1)
      const url = `${this.$apis.smartDevice.exportDeviceData}?token=${token}`
      window.location.href = url + (str ? '&' + str : str)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
